function scroll() {
    $("[data-scroll]").on("click", function (event) {
        event.preventDefault();

        let scrollEl = $(this).data("scroll");

        if (scrollEl != 'top') {
            let scrollElPos = $(scrollEl).offset().top;

            $("html, body").animate(
                {
                    scrollTop: scrollElPos - 50,
                },
                {
                    duration: 1400, // по умолчанию «400»
                    easing: "linear", // по умолчанию «swing»
                }
            );
        }

        if (scrollEl == 'top') {
            $("html, body").animate(
                {
                    scrollTop: 0,
                },
                {
                    duration: 1400, // по умолчанию «400»
                    easing: "linear", // по умолчанию «swing»
                }
            );
        }
    });
}

scroll();